
































































































import { Component, Vue } from 'vue-property-decorator';
import { userService } from '@/services';
import CommonEnum from '@/enums/CommonEnum';
import { User } from '@/models';
import { manageKYCService } from '@/services/ManageKYCService';
import ManageKYCEnum from '@/enums/ManageKYCEnum';

@Component({
  components: {},
})
export default class App extends Vue {
  private get ResponseCodeEnum() {
    return CommonEnum.ResponseCodeEnum;
  }
  private get LanguageEnum() {
    return CommonEnum.LanguageEnum;
  }
  private get LanguageCodeEnum() {
    return CommonEnum.LanguageCodeEnum;
  }
  private KYCStatus = ManageKYCEnum.Status;
  private KYCDocument = ManageKYCEnum.Document;
  private langs = [
    {
      text: '日本語',
      key: this.LanguageEnum.JA,
      code: this.LanguageCodeEnum.JA,
      flag: require('@/assets/images/common/image-language/jp.png'),
    },
    {
      text: 'English',
      key: this.LanguageEnum.EN,
      code: this.LanguageCodeEnum.EN,
      flag: require('@/assets/images/common/image-language/en.png'),
    },
  ];

  private isActiveMenu: boolean = false;
  private intervalid = 0;
  private isDialogNotificationKYC: boolean = false;
  private msgNotificationKYC: string = '';
  private closeDialogNotificationKYC: string = '';

  get user(): User {
    return this.$store.state.user;
  }

  private changeLanguage(lang: string) {
    localStorage.setItem('Language', lang);
    this.axios.defaults.headers.common.Language = lang;
    this.$store.dispatch('setLang', lang);
    this.isActiveMenu = false;
    // this.$router.go(0);
  }

  private onChangeLanguage(key: string, code: number) {
    const jwttoken = localStorage.getItem('jwttoken');
    if (jwttoken) {
      userService.changeLanguage(code)
        .then((res: any) => {
          if (res.data.code === this.ResponseCodeEnum.CODE_SUCCESS) {
            this.changeLanguage(key);
          }
        });
    } else {
      this.changeLanguage(key);
    }
  }

  private created() {
    this.getUserProfile();
    this.intervalid = setInterval(() => {
      this.getUserProfile();
    }, 10000);
  }

  private getUserProfile() {
    this.axios.prototype.loadHeaderInfo();
    if (this.axios.prototype.hasUserInfo()) {
      userService.getProfile().then((res: any) => {
        if (res.data.code === this.ResponseCodeEnum.CODE_SUCCESS) {
          this.$store.dispatch('setUser', res.data.data);
        }
      });
    } else {
      this.axios.prototype.deleteUserInfo();
    }
  }

  private logout() {
    this.axios.prototype.deleteUserInfo();
    this.$router.push({ path: '/' });
  }

  private formatSTP(value: number) {
    return value.toFixed(2);
  }

  private openTransaction() {
    this.$store.dispatch('isLoading', true);
    manageKYCService.getKYCInformation().then((res: any) => {
      if (res.data && res.data.code === this.ResponseCodeEnum.CODE_SUCCESS) {
        this.getUserProfile();
        if (res.data.data.points) {
          if (res.data.data.information) {
            if (res.data.data.information.documentStatus === this.KYCDocument.REQUEST_SENT) {
              this.isDialogNotificationKYC = true;
              this.msgNotificationKYC = 'KYC_page.msg.error.KYC_request_sent';
              this.closeDialogNotificationKYC = 'request_sent';
            } else if (res.data.data.information.kycStatus === this.KYCStatus.DONE) {
              this.$router.push({ name: 'user-transaction-transaction-menu' });
            } else {
              this.isDialogNotificationKYC = true;
              this.msgNotificationKYC = 'KYC_page.msg.error.KYC_pending';
            }
          } else {
            this.isDialogNotificationKYC = true;
            this.msgNotificationKYC = 'KYC_page.msg.error.KYC_upload_documnent';
            this.closeDialogNotificationKYC = 'upload_documnent';
          }
        } else {
          this.$router.push({ name: 'user-transaction-transaction-menu' });
        }
      }
    })
    .finally(() => this.$store.dispatch('isLoading', false));
  }

  private closeDialogKYC() {
    this.isDialogNotificationKYC = false;
    if (this.closeDialogNotificationKYC === 'request_sent') {
      this.$router.push({ name: 'user-KYC' });
    }
    if (this.closeDialogNotificationKYC === 'upload_documnent') {
      this.$router.push({ name: 'user-KYC' });
    }
    this.closeDialogNotificationKYC = '';
  }
}
