import { DataTableRequest } from '@/models';
import Vue from 'vue';

const URI_GET_QUESTION = process.env.VUE_APP_API_URL + '/webapi/question/get-question/';

class QuestionService extends Vue {

  public getQuestion(tournamentPlayHistoryId: number) {
    return this.axios.get(URI_GET_QUESTION + tournamentPlayHistoryId);
  }
}

export const questionService = new QuestionService();
