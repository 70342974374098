import {ViolationDto} from './ViolationDto';
import {InputError} from './InputError';
import i18n from '@/langs/I18n';

class ValidationUtils {

    public getAllMessages(violations: ViolationDto[]): string {
        let message = '';
        if (violations != null) {
            for (let i = 0, len = violations.length; i < len; i++) {
                let violation: ViolationDto;
                violation = violations[i];
                message += i18n.t(violation.message).toString() + '\n';
            }
        }
        return message;
    }

    public loadFieldErrors(errors: any[], inputErrors: InputError[]) {
        errors.forEach((err: any) => {
            const inputError = inputErrors.find((e: any) => e.field === err.field);
            if (inputError !== undefined) {
                if (inputError.isError === undefined) {
                    inputError.isError = true;
                    inputError.message = [];
                }
                inputError.message.push(err.message);
            }
        });
    }

    public getInputError(field: string, errors: InputError[]): InputError | undefined {
        const inputError = errors.find((e: any) => e.field === field);
        return inputError ? inputError : undefined;
    }

    public initErrors(model: any): InputError[] | [] {
        const errors: InputError[] = [];
        for (const key in model) {
            if (model.hasOwnProperty(key)) {
                errors.push({field: key, isError: undefined, message: []});
            }
        }
        return errors;
    }

    public initRulesForm(model: any, getError: any): any | {} {
        const rules = {};
        for (const key in model) {
            if (model.hasOwnProperty(key)) {
                rules[key] = {
                    validator: (rule: any, value: any, callback: any) => getError(rule, value, callback, key),
                };
            }
        }
        return rules;
    }

    public clearError(errors: InputError[]) {
        errors.forEach((err: any) => (err.isError = undefined, err.message = []));
    }
}

export const validationUtils = new ValidationUtils();
