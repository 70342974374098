export class User {
  public id?: number = undefined;
  public nickName?: string = '';
  public userName?: string = '';
  public password?: string = '';
  public confirmPassword?: string = '';
  public email?: string = '';
  public confirmEmail?: string = '';
  public phoneNumber?: string = '';
  public firstName?: string = '';
  public lastName?: string = '';
  public address?: string = '';
  public birthday?: Date = undefined;
  public identityReferenceNumber?: string = '';
  public isAge?: boolean = false;
  public isPolicy?: boolean = false;
  public balance?: number = 0;
  public emailNew?: string = '';

  constructor(init?: Partial<User>) {
      Object.assign(this, init);
  }
}
