export class ManageKYC {
  public id?: number = undefined;
  public userId?: number = undefined;
  public firstName?: string = '';
  public lastName?: string = '';
  public documentType?: string = '';
  public image1?: string = '';
  public image2?: string = '';
  public image3?: string = '';
  public image4?: string = '';
  public image5?: string = '';
  public document?: number = undefined;
  public kyc?: number = undefined;
  public documentSubmissionDate?: Date = undefined;
  public status?: number = undefined;

  public fileImage1 = null;
  public fileImage2 = null;
  public fileImage3 = null;
  public fileImage4 = null;
  public fileImage5 = null;

  constructor(init?: Partial<ManageKYC>) {
    Object.assign(this, init);
  }
}
