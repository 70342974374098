





































































































































































import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { DataTableRequest, User } from '@/models';
import { transactionHistoryService } from '@/services';
import { dateUtils } from '@/utils/DateUtils';

@Component({
  components: {
  },
})
export default class TransactionHistoryPage extends Mixins(CommonMixin) {
  private get DateUtils() {
    return dateUtils;
  }
  private pageCount = 1;
  private transactionHistoryList: any = [];
  private request: DataTableRequest = new DataTableRequest({
    currentPage: 1,
    perPage: 10,
    filter: '',
    sortBy: '',
    sortDesc: true,
  });
  private showTable = false;

  private showDtail(id: string) {
    const trDetail1 = document.getElementById('trDetail1-' + id);
    trDetail1!.style.display = trDetail1!.style.display === 'none' ? '' : 'none';
    const trDetail2 = document.getElementById('trDetail2-' + id);
    trDetail2!.style.display = trDetail2!.style.display === 'none' ? '' : 'none';
    const tdIcon = document.getElementById('tdIcon-' + id);
    tdIcon!.innerHTML = trDetail1!.style.display && trDetail2!.style.display ? '▼' : '▲';
  }

  private async created() {
    this.showTable = true;
    await this.reloadTable();
  }

  private async reloadTable() {
    this.$store.dispatch('isLoading', true);
    await transactionHistoryService.getAll(this.request).then((res: any) => {
      this.transactionHistoryList = res.data.items;
      this.pageCount = res.data.totalRows / this.request.perPage;
    })
    .finally(() => this.$store.dispatch('isLoading', false));
  }

  private changePerPage() {
    this.request.currentPage = 1;
    this.reloadTable();
  }
}
