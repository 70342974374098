class CommonEnum {
  public static readonly HttpStatus = {
    OK: 200,
    CODE_LOGIN_REQUIED: 201,
    BAD_REQUEST: 400,
    CONFLICT: 409,
  };

  public static readonly ResponseCodeEnum = {
    CODE_SUCCESS: 0,
    CODE_PERMISSION: 1,
    CODE_NOT_FOUND: 2,
    CODE_ALREADY_EXIST: 3,
    CODE_BUSINESS: 4,
    CODE_EXPIRED: 8888,
    CODE_INTERNAL_ERROR: 9999,
    CODE_BAD_REQUEST: 400,
  };

  public static readonly LanguageCodeEnum = {
    EN: 2,
    JA: 1,
  };

  public static readonly LanguageEnum = {
    EN: 'en',
    JA: 'ja',
  };
}

export default CommonEnum;
