class PlayHistoryEnum {

  public static readonly Status = {
    BLANK: 0,
    CORRECT: 1,
    INCORRECT: 2,
    TIME_UP: 3,
  };
}

export default PlayHistoryEnum;
