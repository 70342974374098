



































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { Tournament, TournamentView } from '@/models';
import { dateUtils } from '@/utils/DateUtils';

@Component({
  components: {
  },
})
export default class TournamentOnGoing extends Mixins(CommonMixin) {
  @Prop() private tournamentViewList!: TournamentView[];
  private DateUtils = dateUtils;
  private isPrizeDetail: boolean = false;

  private joinTournament(tournament: Tournament) {
    this.$emit('joinTournament', tournament);
  }
}
