
















import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';

@Component({
  components: {
  },
})
export default class AboutUsPage extends Mixins(CommonMixin) {
}
