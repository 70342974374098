import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import store from './store';
import TopPage from '@/views/TopPage.vue';
import RegisterPage from '@/views/user/RegisterPage.vue';
import VerifyPage from '@/views/user/VerifyPage.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';
import LoginPage from '@/views/user/LoginPage.vue';
import ProfilePage from '@/views/user/ProfilePage.vue';
import AboutUsPage from '@/views/AboutUsPage.vue';
import TermsAndConditionsPage from '@/views/TermsAndConditionsPage.vue';
import PrivacyPolicyPage from '@/views/PrivacyPolicyPage.vue';
import AntiMoneyLaunderingPolicyPage from '@/views/AntiMoneyLaunderingPolicyPage.vue';
import ResponsibleGamingPolicyPage from '@/views/ResponsibleGamingPolicyPage.vue';
import TransactionMenuPage from '@/views/user/transaction/TransactionMenuPage.vue';
import LoginEPOWalletPage from '@/views/user/transaction/epo-wallet/LoginEPOWalletPage.vue';
import DepositEPOWalletPage from '@/views/user/transaction/epo-wallet/DepositEPOWalletPage.vue';
import TransactionHistoryPage from '@/views/user/transaction/TransactionHistoryPage.vue';
import TournamentPage from '@/views/tournament/TournamentPage.vue';
import KYCPage from '@/views/user/KYCPage.vue';
import { manageKYCService } from './services/ManageKYCService';
import CommonEnum from './enums/CommonEnum';
import { KYC } from './models';
import ManageKYCEnum from './enums/ManageKYCEnum';
import KYCImageUploadPage from '@/views/KYCImageUploadPage.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history', // history
  base: process.env.BASE_URL,
  routes: [
      {
        path: '*',
        name: 'notfound',
        component: NotFoundPage,
      },
      {
        path: '/',
        name: 'home',
        component: TopPage,
      },
      {
        path: '/user/register',
        name: 'user-register',
        component: RegisterPage,
      },
      {
        path: '/user/verify/:token',
        name: 'user-verify',
        component: VerifyPage,
      },
      {
        path: '/user/login',
        name: 'user-login',
        component: LoginPage,
      },
      {
        path: '/user/profile',
        name: 'user-profile',
        component: ProfilePage,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/about-us',
        name: 'about-us',
        component: AboutUsPage,
      },
      {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: TermsAndConditionsPage,
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicyPage,
      },
      // {
      //   path: '/anti-money-laundering-policy',
      //   name: 'anti-money-laundering-policy',
      //   component: AntiMoneyLaunderingPolicyPage,
      // },
      // {
      //   path: '/responsible-gaming-policy',
      //   name: 'responsible-gaming-policy',
      //   component: ResponsibleGamingPolicyPage,
      // },
      {
        path: '/user/transaction/transaction-menu',
        name: 'user-transaction-transaction-menu',
        component: TransactionMenuPage,
        meta: {
          requiresAuth: true,
          kyc: true,
        },
      },
      {
        path: '/user/transaction/epo/login',
        name: 'user-transaction-epo-login',
        component: LoginEPOWalletPage,
        meta: {
          requiresAuth: true,
          kyc: true,
        },
      },
      {
        path: '/user/transaction/epo/deposit',
        name: 'user-transaction-epo-deposit',
        component: DepositEPOWalletPage,
        meta: {
          requiresAuth: true,
          kyc: true,
        },
      },
      {
        path: '/user/transaction/transaction-history',
        name: 'user-transaction-transaction-history',
        component: TransactionHistoryPage,
        meta: {
          requiresAuth: true,
          kyc: true,
        },
      },
      {
        path: '/tournament',
        name: 'tournament',
        component: TournamentPage,
      },
      {
        path: '/user/KYC',
        name: 'user-KYC',
        component: KYCPage,
        meta: {
          requiresAuth: true,
          kycNotDone: true,
        },
      },
      {
        path: '/KYC-image-upload',
        name: 'KYC-image-upload',
        component: KYCImageUploadPage,
      },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (localStorage.getItem('jwttoken') !== null && (to.path === '/user/login' || to.path === '/user/register') ) {
    next({ path: '/' });
  } else {
    if (to.name === 'userRegisterConfirm' || to.name === 'userRegister' || to.name === 'userLogin'
      || to.name === 'forgetPassword' && from.name === 'userLogin') {
      if (localStorage.getItem('jwttoken') !== null) {
        next({
          name: 'home',
        });
      } else {
        next();
      }
    }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (localStorage.getItem('jwttoken') == null) {
        store.dispatch('setAuthenticated', false);
        delete axios.defaults.headers.common.Authorization;
        next({
          path: '/user/login',
          params: {nextUrl: to.fullPath},
        });
      } else {
        if (to.matched.some((record) => record.meta.kyc)) {
          let kyc: KYC = new KYC();
          await manageKYCService.getKYCInformation().then((res: any) => {
            if (res.data.code === CommonEnum.ResponseCodeEnum.CODE_SUCCESS) {
              kyc = res.data.data;
            }
          });

          if (!kyc) {
            next({ path: '/' });
          }

          if (kyc.points) {
            if (kyc.information && kyc.information.kycStatus === ManageKYCEnum.Status.DONE) {
              next();
            } else {
              next({ path: '/' });
            }
          } else {
            next();
          }
        }
        if (to.matched.some((record) => record.meta.kycNotDone)) {
          let kyc: KYC = new KYC();
          await manageKYCService.getKYCInformation().then((res: any) => {
            if (res.data.code === CommonEnum.ResponseCodeEnum.CODE_SUCCESS) {
              kyc = res.data.data;
            }
          });

          if (!kyc) {
            next({ path: '/' });
          }

          if (
            !kyc.information ||
            kyc.information &&
            kyc.information.kycStatus !== ManageKYCEnum.Status.DONE &&
            kyc.information.documentStatus === ManageKYCEnum.Document.REQUEST_SENT
          ) {
            next();
          } else {
            next({ path: '/' });
          }
        }
        next();
      }
    } else if (to.matched.some((record) => record.meta.guest)) {
      if (localStorage.getItem('jwttoken') == null) {
        next();
      } else {
        next({name: 'userboard'});
      }
    } else {
      next();
    }
  }
});
export default router;
