import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enMessage from './en.json';
import jaMessage from './ja.json';
import store from '../store';
import element_en from 'element-ui/lib/locale/lang/en';
import element_ja from 'element-ui/lib/locale/lang/ja';

Vue.use(VueI18n);

const messages = {
    ja: {
        ...jaMessage,
        ...element_ja,
      },
      en: {
        ...enMessage,
        ...element_en,
      },
};

const i18n = new VueI18n({
    locale: store.state.storedLang, // set locale
    messages,
    fallbackLocale: 'ja',
    silentTranslationWarn: true,
});

export default i18n;
