




























































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';

@Component({
  components: {
  },
})
export default class TermsAndConditionsPage extends Mixins(CommonMixin) {
  private get language() {
    return this.$store.state.storedLang;
  }

  @Watch('language')
  private onChangeLang(val: string, oldVal: string) {
    this.$nextTick(() => {
      const linkHome = document.getElementsByClassName('linkHome');
      if (linkHome) {
        for (const link of linkHome) {
          link.addEventListener('click', this.toPageHome);
        }
      }
    });
  }

  private toPageHome(event: Event) {
    event.preventDefault();
    this.$router.push({ path: '/' });
  }

  private created() {
    this.$nextTick(() => {
      const linkHome = document.getElementsByClassName('linkHome');
      if (linkHome) {
        for (const link of linkHome) {
          link.addEventListener('click', this.toPageHome);
        }
      }
    });
  }
}
