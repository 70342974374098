
































import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { User } from '@/models';

@Component({
  components: {
  },
})
export default class TransactionMenuPage extends Mixins(CommonMixin) {
  get user(): User {
    return this.$store.state.user;
  }
  private transactionMenu: any;

  private formatSTP(value: number) {
    return value.toFixed(2);
  }

  private async created() {
    this.transactionMenu = [
      {
        to: '/user/transaction/epo/login',
        image: 'wallet.png',
        label: 'Deposit with <br /> Wallet',
      },
      {
        to: '/user/transaction/transaction-history',
        image: 'transaction-history.png',
        label: 'Transaction <br /> History',
      },
    ];
  }
}
