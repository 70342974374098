



























































































































import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { userService } from '@/services';
import { User } from '@/models';
import CommonEnum from '@/enums/CommonEnum';
import { validationUtils } from '@/validation/ValidationUtils';
import { InputError } from '@/validation/InputError';
import { manageKYCService } from '@/services/ManageKYCService';
import ManageKYCEnum from '@/enums/ManageKYCEnum';

@Component({
  components: {
  },
})
export default class ProfilePage extends Mixins(CommonMixin) {
  private get HttpStatus() {
    return CommonEnum.HttpStatus;
  }
  private get ResponseCodeEnum() {
    return CommonEnum.ResponseCodeEnum;
  }
  private KYCStatus = ManageKYCEnum.Status;
  private KYCDocument = ManageKYCEnum.Document;
  private validationUtils = validationUtils;
  private errors: InputError[] = [];
  private isAlertUpdateNickNameSuccess = false;
  private isAlertUpdateEmailSuccess = false;
  private isAlertUpdatePasswordSuccess = false;
  private isAlertUpdatePhoneNumberSuccess = false;

  private isDialogNotificationKYC: boolean = false;
  private msgNotificationKYC: string = '';
  private closeDialogNotificationKYC: string = '';

  private user: User = new User();
  get userStore(): User {
    return this.$store.state.user;
  }

  private rulesUpdate = {};

  private getError(rule: any, value: any, callback: any, field: string) {
    this.errors.forEach((e: InputError) => {
      if (e.field === field && e.isError) {
        callback(new Error(this.$t(e.message[0]).toString()));
      }
    });
    callback();
  }

  private async created() {
    this.errors = validationUtils.initErrors(this.user);
    this.rulesUpdate = this.validationUtils.initRulesForm(this.user, this.getError);
    await this.getUserProfile();
  }

  private async getUserProfile() {
    this.$store.dispatch('isLoading', true);
    this.axios.prototype.loadHeaderInfo();
    if (this.axios.prototype.hasUserInfo()) {
      await userService.getProfile().then((res: any) => {
        if (res.data.code === this.ResponseCodeEnum.CODE_SUCCESS) {
          this.user = res.data.data;
          this.$store.dispatch('setUser', res.data.data);
        }
      })
      .finally(() => this.$store.dispatch('isLoading', false));
    }
  }

  private formatSTP(value: number) {
    return value.toFixed(2);
  }

  private updateProfile(field: string) {
    validationUtils.clearError(this.errors);
    if (field === 'nickName') {
      (this.$refs.formUpdateNickName as any).validate();
    }
    if (field === 'email') {
      (this.$refs.formUpdateEmail as any).validate();
    }
    if (field === 'password') {
      (this.$refs.formUpdatePassword as any).validate();
    }
    if (field === 'phoneNumber') {
      (this.$refs.formUpdatePhoneNumber as any).validate();
    }
    this.isAlertUpdateNickNameSuccess = false;
    this.isAlertUpdateEmailSuccess = false;
    this.isAlertUpdatePasswordSuccess = false;
    this.isAlertUpdatePhoneNumberSuccess = false;
    this.$store.dispatch('isLoading', true);
    userService.updateProfile(this.user, field).then((res: any) => {
      if (res.status === this.HttpStatus.OK) {
        if (field === 'nickName') {
          this.isAlertUpdateNickNameSuccess = true;
        }
        if (field === 'email') {
          this.isAlertUpdateEmailSuccess = true;
        }
        if (field === 'password') {
          this.isAlertUpdatePasswordSuccess = true;
        }
        if (field === 'phoneNumber') {
          this.isAlertUpdatePhoneNumberSuccess = true;
        }
        this.getUserProfile();
      }
    })
    .catch((error: any) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.violations
      ) {
        validationUtils.loadFieldErrors(
          error.response.data.violations,
          this.errors,
        );

        if (field === 'nickName') {
          (this.$refs.formUpdateNickName as any).validate();
        }
        if (field === 'email') {
          (this.$refs.formUpdateEmail as any).validate();
        }
        if (field === 'password') {
          (this.$refs.formUpdatePassword as any).validate();
        }
        if (field === 'phoneNumber') {
          (this.$refs.formUpdatePhoneNumber as any).validate();
        }

        if (
          this.errors.filter((e: any) => e.isError === true) &&
          this.errors.filter((e: any) => e.isError === true)[0].field
        ) {
          (this.$refs[this.errors.filter((e: any) => e.isError === true)[0].field as string] as any).focus();
        }
      }
    }).finally(() => this.$store.dispatch('isLoading', false));
  }

  private closeDialogKYC() {
    this.isDialogNotificationKYC = false;
    if (this.closeDialogNotificationKYC === 'request_sent') {
      this.$router.push({ name: 'user-KYC' });
    }
    if (this.closeDialogNotificationKYC === 'upload_documnent') {
      this.$router.push({ name: 'user-KYC' });
    }
    this.closeDialogNotificationKYC = '';
  }

  private registerKYC() {
    this.$store.dispatch('isLoading', true);
    manageKYCService.getKYCInformation().then((res: any) => {
      if (res.data && res.data.code === this.ResponseCodeEnum.CODE_SUCCESS) {
        if (res.data.data.points) {
          if (res.data.data.information) {
            if (res.data.data.information.documentStatus === this.KYCDocument.REQUEST_SENT) {
              this.$router.push({ name: 'user-KYC' });
            } else if (res.data.data.information.kycStatus === this.KYCStatus.DONE) {
              this.isDialogNotificationKYC = true;
              this.msgNotificationKYC = 'KYC_page.msg.error.KYC_done';
            } else {
              this.isDialogNotificationKYC = true;
              this.msgNotificationKYC = 'KYC_page.msg.error.KYC_pending_2';
            }
          } else {
            this.$router.push({ name: 'user-KYC' });
          }
        } else {
          if (res.data.data.information) {
            if (res.data.data.information.kycStatus === this.KYCStatus.DONE) {
              this.isDialogNotificationKYC = true;
              this.msgNotificationKYC = 'KYC_page.msg.error.KYC_done';
              return;
            }
            if (res.data.data.information.documentStatus === this.KYCDocument.REQUEST_SENT) {
              this.$router.push({ name: 'user-KYC' });
              return;
            }
            this.isDialogNotificationKYC = true;
            this.msgNotificationKYC = 'KYC_page.msg.error.KYC_pending_2';
            return;
          }
          this.$router.push({ name: 'user-KYC' });
        }
      }
    })
    .finally(() => this.$store.dispatch('isLoading', false));
  }
}
