






import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Loading extends Vue {
  get isLoading(): boolean {
    return this.$store.state.isLoading;
  }
}
