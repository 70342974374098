import { DataTableRequest } from '@/models';
import Vue from 'vue';

const URI_GET_ALL = process.env.VUE_APP_API_URL + '/webapi/tournament';
const URI_JOIN_TOURNAMENT = process.env.VUE_APP_API_URL + '/webapi/tournament/join-tournament/';
const URI_CONFIRM_JOIN_TOURNAMENT = process.env.VUE_APP_API_URL + '/webapi/tournament/confirm-join-tournament/';
const URI_GET_QUESTION = process.env.VUE_APP_API_URL + '/webapi/tournament/get-question/';
const URI_CHOOSE_ANSWER_QUESTION = process.env.VUE_APP_API_URL + '/webapi/tournament/choose-answer-question/';

class TournamentService extends Vue {

  public getAll() {
    return this.axios.get(URI_GET_ALL);
  }

  public joinTournament(tournamentId: number) {
    return this.axios.get(URI_JOIN_TOURNAMENT + tournamentId);
  }

  public confirmJoinTournament(tournamentId: number) {
    return this.axios.post(URI_CONFIRM_JOIN_TOURNAMENT + tournamentId);
  }

  public getQuestion(tournamentPlayHistoryId: number) {
    return this.axios.get(URI_GET_QUESTION + tournamentPlayHistoryId);
  }

  public chooseAnswerQuestion(playHistoryId: number, questionId: number, answer: number ) {
    return this.axios.post(URI_CHOOSE_ANSWER_QUESTION + playHistoryId + '/' + questionId + '/' + answer);
  }
}

export const tournamentService = new TournamentService();
