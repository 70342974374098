class TournamentEnum {

  public static readonly ExecutionScreen = {
    JOIN_TOURNAMENT: 'JOIN_TOURNAMENT',
    CONFIRM_QUESTION: 'CONFIRM_QUESTION',
    QUESTION: 'QUESTION',
    QUESTION_RESULT: 'QUESTION_RESULT',
    TIME_UP: 'TIME_UP',
    COMPLETE_TOURNAMENT: 'COMPLETE_TOURNAMENT',
    CONFIRM_END_ROUND: 'CONFIRM_END_ROUND',
    CONTINUTE_ROUND: 'CONTINUTE_ROUND',
  };
}

export default TournamentEnum;
