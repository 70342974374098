














import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';

@Component({
  components: {
  },
})
export default class NotFoundPage extends Mixins(CommonMixin) {
  private toTOP() {
    this.$router.push({ path: '/' });
  }
}
