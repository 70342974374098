
























import { Component, Mixins, Prop } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { TournamentView } from '@/models';
import { dateUtils } from '@/utils/DateUtils';

@Component({
  components: {
  },
})
export default class TournamentUpComing extends Mixins(CommonMixin) {
  private DateUtils = dateUtils;
  @Prop() private tournamentViewList!: TournamentView[];

  private genTotalPrize(t: TournamentView) {
    return t.tournament.prizeRank1 +
    t.tournament.prizeRank2 +
    t.tournament.prizeRank3 +
    t.tournament.prizeRank4 +
    t.tournament.prizeRank5 +
    t.tournament.prizeRank6 +
    t.tournament.prizeRank7 +
    t.tournament.prizeRank8 +
    t.tournament.prizeRank9 +
    t.tournament.prizeRank10;
  }
}
