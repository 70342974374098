export class Question {
  public id?: number = undefined;
  public question?: string = '';
  public answer1?: string = '';
  public answer2?: string = '';
  public answer3?: string = '';
  public answer4?: string = '';
  public answer5?: string = '';

  constructor(init?: Partial<Question>) {
    Object.assign(this, init);
  }
}
