import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment-timezone';
import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './langs/I18n';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import vueCountryRegionSelect from 'vue-country-region-select';
import VueCircleSlider from 'vue-circle-slider';

import ElementUI from 'element-ui';
import '@/assets/scss/element-variables.scss';
Vue.use(ElementUI, {
  i18n: (key: string, value: string) => i18n.t(key, value),
});

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

import Paginate from 'vuejs-paginate';
Vue.component('paginate', Paginate);

Vue.use(vueCountryRegionSelect);
Vue.use(BootstrapVue);
Vue.use(VueCircleSlider);
axios.interceptors.request.use((config) => {
  const jwttoken = localStorage.getItem('jwttoken');
  if (jwttoken) {
    config.headers.common.Authorization = jwttoken;
  }
  config.headers.common.Language = store.state.storedLang;
  return config;
}, (error) => {
  return Promise.reject(error);
});

// check if any response if have error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // remove authenticate
      axios.prototype.deleteUserInfo();

      router.push({path: '/user/login'});
    }
    return Promise.reject(error);
  },
);

// set authorization when the web is run (global)
axios.prototype.loadHeaderInfo = () => {
  // load user's info
  const jwttoken = localStorage.getItem('jwttoken');
  if (jwttoken) {
    axios.defaults.headers.common.Authorization = jwttoken;
  }

  // load language
  // const lang = localStorage.getItem('Language');
  axios.defaults.headers.common.Language = store.state.storedLang;
};
axios.prototype.loadHeaderInfo();

// tslint:disable-next-line: only-arrow-functions
axios.prototype.hasUserInfo = () => {
  return (axios.defaults.headers.common.Authorization != null) ? true : false;
};


// set user infomation
axios.prototype.setUserInfo = (token: string) => {
  const jwttoken = 'jwttoken:' + token;
  localStorage.setItem('jwttoken', jwttoken);
  axios.defaults.headers.common.Authorization = jwttoken;
};

// delete all user information of axios
axios.prototype.deleteUserInfo = () => {
  localStorage.removeItem('jwttoken');
  store.dispatch('setUser', {});
  delete axios.defaults.headers.common.Authorization;
};

Vue.use(VueAxios, axios);

// register moment
moment.locale(i18n.locale);
// moment.tz.setDefault('UTC');
Vue.prototype.$moment = moment;

Vue.filter('formatDate', (value: any) => {
  if (value) {
    return moment(String(value)).format('MM-DD-YYYY hh:mm');
  }
});

Vue.config.productionTip = false;
declare module 'vue/types/vue' {
  interface Vue {
    $moment: any;
  }
}

// assign the asset url
Vue.prototype.ASSET_URL = process.env.VUE_APP_ASSET_URL;

// mount app to layout
const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

export default app;
