











































































































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import TournamentEnum from '@/enums/TournamentEnum';
import { Question, Tournament } from '@/models';
import { questionService, tournamentService } from '@/services';
import CommonEnum from '@/enums/CommonEnum';
import PlayHistoryEnum from '@/enums/PlayHistoryEnum';

@Component({
  components: {
  },
})
export default class PlayGame extends Mixins(CommonMixin) {
  @Prop() private isShow!: boolean;
  @Prop({ default: null }) private screenPlay!: string;
  @Prop({ default: 0 }) private questionsAnswered!: number;
  @Prop({ default: 0 }) private correctAnswers!: number;
  @Prop({ default: null }) private tournamentPlayHistoryId!: number;
  @Prop() private tournament!: Tournament;

  private isMsgError: boolean = false;
  private msgError: string = '';

  private ResponseCode = CommonEnum.ResponseCodeEnum;
  private PlayHistoryEnum = PlayHistoryEnum;
  private TournamentEnum = TournamentEnum;

  private question: Question = new Question();

  private playHistoryId: number | null = null;

  private answerResult: number | null = null;

  private countdown: number = 10;
  private interval: any = null;

  private isLoadingPlayGame: boolean = false;

  private mounted() {
    this.preventCopy('enable');
  }

  private confirmJoinTournament() {
    if (this.tournament.id) {
      this.isLoadingPlayGame = true;
      this.isMsgError = false;
      this.msgError = '';
      tournamentService.confirmJoinTournament(this.tournament.id).then((res: any) => {
        if (res.data.code === this.ResponseCode.CODE_SUCCESS) {
          this.$emit('update:tournamentPlayHistoryId', res.data.data);
          this.$emit('update:screenPlay', this.TournamentEnum.ExecutionScreen.CONFIRM_QUESTION);
        } else {
          this.isMsgError = res.data.code;
          this.msgError = res.data.data;
        }
      }).finally(() => this.isLoadingPlayGame = false);
    }
  }

  private getQuestion() {
    if (this.tournamentPlayHistoryId) {
      this.isLoadingPlayGame = true;
      this.isMsgError = false;
      this.msgError = '';
      tournamentService.getQuestion(this.tournamentPlayHistoryId).then((res: any) => {
        if (res.data.code === this.ResponseCode.CODE_SUCCESS) {
          this.question = res.data.data.question;
          this.playHistoryId = res.data.data.playHistoryId;
          this.$emit('update:screenPlay', this.TournamentEnum.ExecutionScreen.QUESTION);
          this.countdown = 10;
          this.startCountdown();
        } else {
          this.isMsgError = res.data.code;
          this.msgError = res.data.data;
        }
      }).finally(() => this.isLoadingPlayGame = false);
    }
  }

  private continuteRound() {
    this.isLoadingPlayGame = true;
    this.$emit('update:screenPlay', this.TournamentEnum.ExecutionScreen.CONFIRM_QUESTION);
    this.isLoadingPlayGame = false;
  }

  private chooseAnswer(answer: number) {
    if (this.playHistoryId && this.question.id) {
      this.isLoadingPlayGame = true;
      clearInterval(this.interval);
      this.isMsgError = false;
      this.msgError = '';
      tournamentService.chooseAnswerQuestion(this.playHistoryId, this.question.id, answer).then((res: any) => {
        if (res.data.code === this.ResponseCode.CODE_SUCCESS) {
          this.$emit('update:questionsAnswered', this.questionsAnswered + 1);
          if (res.data.data === this.PlayHistoryEnum.Status.CORRECT) {
            this.answerResult = this.PlayHistoryEnum.Status.CORRECT;
            this.$emit('update:correctAnswers', this.correctAnswers + 1);
            this.$emit('update:screenPlay', this.TournamentEnum.ExecutionScreen.QUESTION_RESULT);
          }
          if (res.data.data === this.PlayHistoryEnum.Status.INCORRECT) {
            this.answerResult = this.PlayHistoryEnum.Status.INCORRECT;
            this.$emit('update:screenPlay', this.TournamentEnum.ExecutionScreen.QUESTION_RESULT);
          }
        } else if (res.data.code === this.ResponseCode.CODE_BAD_REQUEST) {
          this.$emit('update:questionsAnswered', this.questionsAnswered + 1);
          this.answerResult = this.PlayHistoryEnum.Status.TIME_UP;
          this.$emit('update:screenPlay', this.TournamentEnum.ExecutionScreen.QUESTION_RESULT);
        }
      }).finally(() => this.isLoadingPlayGame = false);
    }
  }

  private startCountdown() {
    this.interval = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.interval);
          this.$emit('update:questionsAnswered', this.questionsAnswered + 1);
          this.answerResult = this.PlayHistoryEnum.Status.TIME_UP;
          this.$emit('update:screenPlay', this.TournamentEnum.ExecutionScreen.QUESTION_RESULT);
          this.countdown = 10;
        }
      }
      // else {
      //   clearInterval(this.interval);
      //   this.$emit('update:questionsAnswered', this.questionsAnswered + 1);
      //   this.answerResult = this.PlayHistoryEnum.Status.TIME_UP;
      //   this.$emit('update:screenPlay', this.TournamentEnum.ExecutionScreen.QUESTION_RESULT);
      //   this.countdown = 10;
      // }
    }, 1000);
  }

  private endRound() {
    this.$emit('update:screenPlay', this.TournamentEnum.ExecutionScreen.CONFIRM_END_ROUND);
  }

  private checkNextRound() {
    if (this.tournament.questionRound) {
      return this.questionsAnswered % this.tournament.questionRound === 0;
    }
    return false;
  }

  private checkCompleteTournament() {
    if (this.tournament.totalQuestions) {
      return this.questionsAnswered >= this.tournament.totalQuestions;
    }
    return false;
  }

  private preventCopy(status: string) {
    if (status === 'enable') {
      document.addEventListener('copy', (e) => e.preventDefault());
      document.addEventListener('cut', (e) => e.preventDefault());
      document.addEventListener('contextmenu', (e) => e.preventDefault());
    } else {
      document.removeEventListener('copy', (e) => e.preventDefault());
      document.removeEventListener('cut', (e) => e.preventDefault());
      document.removeEventListener('contextmenu', (e) => e.preventDefault());
    }
  }

  private confirmEndRound() {
    this.$emit('update:isShow', false);
    this.$emit('realoadRanking');
  }

  private completeTournament() {
    this.$emit('update:isShow', false);
    this.$emit('realoadRanking');
  }

  private closeDialogPlayGame() {
    this.$emit('update:isShow', false);
    this.$emit('realoadRanking');
  }
}
