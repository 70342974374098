import Vue from 'vue';
import Vuex from 'vuex';
import mainApp from './main';

// @ts-ignore
import createPersist from 'vuex-localstorage';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersist({
    namespace: 'steppoints.root',
    initialState: {},
  })],
  state: {
    storedLang: localStorage.getItem('Language') ? localStorage.getItem('Language')!.toString() : 'ja',
    user: {
      balance: 0,
    },
    locale: 'ja',
    isLoading: false,
  },
  mutations: {
    SET_LANG(state, storedLang) {
      mainApp.$i18n.locale = storedLang;
      state.storedLang = storedLang;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_LOCALE(state, locale) {
      state.locale = locale;
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_BALANCE_USER(state, payload) {
      state.user.balance = payload;
    },
  },
  actions: {
    setLang({commit}, storedLang) {
      commit('SET_LANG', storedLang);
    },
    setUser({commit}, user) {
      commit('SET_USER', user);
    },
    setLocale({commit}, locale) {
      commit('SET_LOCALE', locale);
    },
    isLoading({commit}, isLoading) {
      commit('IS_LOADING', isLoading);
    },
    setBalanceUser({commit}, payload) {
      commit('SET_BALANCE_USER', payload);
    },
  },
});
