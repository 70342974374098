import moment from 'moment';
import momentTZ from 'moment-timezone';

class DateUtils {

    public getDateTime(date: Date, timeZone?: string, format: string = 'YYYY/MM/DD HH:mm:ss') {
        let strLocalDate = moment(date).local().format(format);
        if (timeZone) {
            strLocalDate = moment(strLocalDate).utcOffset(timeZone).format(format);
        }
        return strLocalDate;
    }

    public convertUTCDateToLocal(date: Date, timeZone?: string, format: string = 'YYYY/MM/DD HH:mm:ss'): Date {
        const utcDate = moment.utc(date).toDate();
        let strLocalDate = moment(utcDate).local().format(format);
        if (timeZone) {
            strLocalDate = moment(strLocalDate).utcOffset(timeZone).format(format);
        }
        const localDate: Date = new Date(strLocalDate);
        return localDate;
    }

    public getCurrentTime(timeZone?: string, format: string = 'YYYY/MM/DD HH:mm:ss'): Date {
        let strLocalDate = moment().local().format(format);
        if (timeZone) {
            strLocalDate = moment(strLocalDate).utcOffset(timeZone).format(format);
        }
        const localDate: Date = new Date(strLocalDate);
        return localDate;
    }

    public convertDateToMoment(date: Date) {
        return moment(date);
    }

    public getCurrentMonthUTC(): number {
        return moment().utc().month() + 1;
    }

    public getCurrentYearUTC(): number {
        return moment().utc().year();
    }

    // public convertToSafariDate(date:Date){
    //     let strDate=date.toString().replace(/-/g, "/");
    //     return new Date(strDate);
    // }

    public getTimeZone() {
        return momentTZ().tz(momentTZ.tz.guess()).format('Z');
    }

    public formatDateTime(dateTime: Date, format: string) {
        return moment(dateTime).format(format);
    }

    public formatDateTimeUTC(dateTime: Date, format: string) {
        return moment(dateTime).utc().format(format);
    }
}

export const dateUtils = new DateUtils();
