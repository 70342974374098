








































































import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { tournamentService } from '@/services';
import { Tournament, TournamentView } from '@/models';
import CommonEnum from '@/enums/CommonEnum';
import { dateUtils } from '@/utils/DateUtils';
import TournamentOnGoing from './TournamentOnGoing.vue';
import TournamentUpComing from './TournamentUpComing.vue';
import TournamentResult from './TournamentResult.vue';
import PlayGame from './PlayGame.vue';
import TournamentEnum from '@/enums/TournamentEnum';

@Component({
  components: {
    TournamentOnGoing,
    TournamentUpComing,
    TournamentResult,
    PlayGame,
  },
})
export default class TournamentPage extends Mixins(CommonMixin) {
  private tournamentOnGoingList: TournamentView[] = [];
  private tournamentUpComingList: TournamentView[] = [];
  private tournamentResultList: TournamentView[] = [];
  private ResponseCode = CommonEnum.ResponseCodeEnum;
  private DateUtils = dateUtils;

  private TournamentEnum = TournamentEnum;

  private screenPlay: string | null = null;
  private isPlayGame: boolean = false;
  private isCompleteTournament: boolean = false;

  private questionsAnswered: number = 0;
  private correctAnswers: number = 0;

  private tournamentPlayHistoryId: number = 0;

  private tournament: Tournament = new Tournament();

  private created() {
    this.showLoading();
    tournamentService.getAll().then((res: any) => {
      if (res.data.code === this.ResponseCode.CODE_SUCCESS) {
        this.tournamentOnGoingList = res.data.data.tournamentOnGoingList;
        this.tournamentUpComingList = res.data.data.tournamentUpComingList;
        this.tournamentResultList = res.data.data.tournamentResultList;
      }
    }).finally(() => this.hideLoading());
  }

  private realoadRanking() {
    this.showLoading();
    tournamentService.getAll().then((res: any) => {
      if (res.data.code === this.ResponseCode.CODE_SUCCESS) {
        this.tournamentOnGoingList = res.data.data.tournamentOnGoingList;
        this.tournamentUpComingList = res.data.data.tournamentUpComingList;
        this.tournamentResultList = res.data.data.tournamentResultList;
      }
    }).finally(() => this.hideLoading());
  }

  private joinTournament(tournament: Tournament) {
    if (tournament.id) {
      this.showLoading();
      tournamentService.joinTournament(tournament.id).then((res: any) => {
        if (res.data.code === this.ResponseCode.CODE_SUCCESS) {
          this.tournament = tournament;
          if (
            res.data.data.questionsAnswered &&
            res.data.data.questionsAnswered.questionsAnswered >= tournament.totalQuestions!
          ) {
            this.isCompleteTournament = true;
          } else if (res.data.data.tournamentPlayHistoryId) {
            this.tournamentPlayHistoryId = res.data.data.tournamentPlayHistoryId;
            this.screenPlay = this.TournamentEnum.ExecutionScreen.CONTINUTE_ROUND;
            this.isPlayGame = true;
            this.questionsAnswered = res.data.data.questionsAnswered.questionsAnswered;
            this.correctAnswers = res.data.data.questionsAnswered.correctAnswers;
          } else if (res.data.data.questionsAnswered && !res.data.data.tournamentPlayHistoryId) {
            this.screenPlay = this.TournamentEnum.ExecutionScreen.JOIN_TOURNAMENT;
            this.isPlayGame = true;
            this.questionsAnswered = res.data.data.questionsAnswered.questionsAnswered;
            this.correctAnswers = res.data.data.questionsAnswered.correctAnswers;
          }
        }
      })
      .finally(() => this.hideLoading());
    }
  }
}
