class ManageKYCEnum {
  public static Document = {
    PENDING: 1,
    DONE: 2,
    REQUEST_SENT: 3,
  };

  public static Status = {
    PENDING: 1,
    DONE: 2,
  };
}

export default ManageKYCEnum;
