import Vue from 'vue';

const URI_REGISTER_KYC = process.env.VUE_APP_API_URL + '/webapi/manage-KYC/register-KYC';
const URI_KYC_INFORMATION = process.env.VUE_APP_API_URL + '/webapi/manage-KYC/get-KYC-information';

class ManageKYCService extends Vue {

  public registerKYC(data: any) {
    return this.axios.post(URI_REGISTER_KYC, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
  }

  public getKYCInformation() {
    return this.axios.get(URI_KYC_INFORMATION);
  }
}

export const manageKYCService = new ManageKYCService();
