

























































































































































































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';

@Component({
  components: {},
})
export default class KYCImageUploadPage extends Mixins(CommonMixin) {}
