










































































































import { Component, Mixins } from 'vue-property-decorator';

import {userService} from '@/services';
import { User } from '@/models';
import {validationUtils} from '@/validation/ValidationUtils';
import CommonEnum from '@/enums/CommonEnum';
import {InputError} from '@/validation/InputError';
import CommonMixin from '@/components/CommonMixin';
import Cookies from 'js-cookie';
import $ from 'jquery';
import 'daterangepicker';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { dateUtils } from '@/utils/DateUtils';

@Component({
  components: {
    Datepicker,
  },
})
export default class RegisterPage extends Mixins(CommonMixin) {
  private get HttpStatus() {
    return CommonEnum.HttpStatus;
  }
  private validationUtils = validationUtils;
  private errors: InputError[] = [];
  private isAlertError = false;
  private isAlertSuccess = false;
  private isConfirmRules: boolean = false;

  private user: User = new User();
  private rulesRegister = {};

  private getError(rule: any, value: any, callback: any, field: string) {
    this.errors.forEach((e: InputError) => {
      if (e.field === field && e.isError) {
        callback(new Error(this.$t(e.message[0]).toString()));
      }
    });
    callback();
  }

  private created() {
    if (!(this.$route.query.ref === null || this.$route.query.ref === undefined)) {
      Cookies.set('register-affiliate-id', this.$route.query.ref);
    }
    this.errors = validationUtils.initErrors(this.user);
    this.rulesRegister = this.validationUtils.initRulesForm(this.user, this.getError);
  }

  private register() {
    validationUtils.clearError(this.errors);
    (this.$refs.formRegister as any).validate();
    this.isAlertError = false;
    this.isAlertSuccess = false;
    this.$store.dispatch('isLoading', true);
    if (
      !this.user.isAge ||
      !this.user.isPolicy
    ) {
      this.$store.dispatch('isLoading', false);
      this.isConfirmRules = true;
      return;
    }
    this.axios.prototype.loadHeaderInfo();
    userService.register(this.user).then((res: any) => {
      if (res.status === this.HttpStatus.OK) {
        this.isAlertSuccess = true;
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    })
    .catch((error: any) => {
      if (error.response.data.violations) {
        if (!error.response.data.violations[0].field) {
          this.isAlertError = true;
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          return;
        }

        validationUtils.loadFieldErrors(
          error.response.data.violations,
          this.errors,
        );

        (this.$refs.formRegister as any).validate();

        if (
          this.errors.filter((e: any) => e.isError === true) &&
          this.errors.filter((e: any) => e.isError === true)[0].field
        ) {
          (this.$refs[this.errors.filter((e: any) => e.isError === true)[0].field as string] as any).focus();
        }
      }

      if (error.response.data.error) {
        this.isAlertError = true;
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }).finally(() => this.$store.dispatch('isLoading', false));
  }
}
