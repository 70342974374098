






































import { Component, Mixins } from 'vue-property-decorator';
import CommonMixin from '@/components/CommonMixin';
import { epoWalletService } from '@/services';
import CommonEnum from '@/enums/CommonEnum';

@Component({
  components: {
  },
})
export default class LoginEPOWalletPage extends Mixins(CommonMixin) {
  private get HttpStatus() {
    return CommonEnum.HttpStatus;
  }
  private get ResponseCodeEnum() {
    return CommonEnum.ResponseCodeEnum;
  }
  private password: string = '';
  private email: string = '';
  private isAlertError: boolean = false;
  private msgError: string = '';

  private mounted() {
    this.getAccount();
  }

  private getAccount() {
    this.$store.dispatch('isLoading', true);
    epoWalletService.getAccount().then((res: any) => {
      if (
        res.status === this.HttpStatus.OK &&
        res.data.code === this.ResponseCodeEnum.CODE_SUCCESS
      ) {
        this.email = res.data.data;
      }
    })
    .finally(() => this.$store.dispatch('isLoading', false));
  }

  private login() {
    this.isAlertError = false;
    this.msgError = '';
    this.$store.dispatch('isLoading', true);
    epoWalletService.login(this.email, this.password).then((res: any) => {
      if (
        res.status === this.HttpStatus.OK &&
        res.data.code === this.ResponseCodeEnum.CODE_SUCCESS
      ) {
        this.$router.push({
          name: 'user-transaction-epo-deposit',
          query: {
            token: res.data.data,
          },
        });
      } else if (
        res.status === this.HttpStatus.OK &&
        res.data.code !== this.ResponseCodeEnum.CODE_SUCCESS
      ) {
        this.isAlertError = true;
        this.msgError = res.data.data;
      }
    })
    .finally(() => this.$store.dispatch('isLoading', false));
  }
}
