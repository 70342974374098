import Vue from 'vue';
import { AxiosPromise } from 'axios';
import { Response, User } from '@/models';

const URI_LOGIN = process.env.VUE_APP_API_URL + '/auth/authenticate';
const URI_CHECK_USER_LOGIN = process.env.VUE_APP_API_URL + '/webapi/user/checkUserIsAuth';
const URI_RESET_PASSWORD = process.env.VUE_APP_API_URL + '/webapi/user/resetPassword';
const URI_GET_CURRENT_USER_NAME = process.env.VUE_APP_API_URL + '/webapi/user/getCurrentUserName';
const URI_REGISTER_USER = process.env.VUE_APP_API_URL + '/webapi/user/registerUser';
const URI_FORGET_PASSWORD = process.env.VUE_APP_API_URL + '/webapi/user/forgetPassword';
const URI_ACTIVE_USER = process.env.VUE_APP_API_URL + '/webapi/user/activeUser';
const URI_GET_USER_PROFILE = process.env.VUE_APP_API_URL + '/webapi/user/getUserProfile';
const URI_UPDATE_USER_PROFILE = process.env.VUE_APP_API_URL + '/webapi/user/updateUserProfile';
const URI_REGISTER = process.env.VUE_APP_API_URL + '/webapi/user/register';
const URI_VERIFY = process.env.VUE_APP_API_URL + '/webapi/user/verify';
const URI_GET_PROFILE = process.env.VUE_APP_API_URL + '/webapi/user/get-profile';
const URI_UPDATE_PROFILE = process.env.VUE_APP_API_URL + '/webapi/user/update-profile/';
const URI_CHANGE_LANGUAGE = process.env.VUE_APP_API_URL + '/webapi/user/change-language/';

class UserService extends Vue {

  public logIn(username: string, password: string) {
    return this.axios.post(URI_LOGIN, {username, password});
  }

  public getCurrentUserName() {
    return this.axios.post(URI_GET_CURRENT_USER_NAME);
  }

  public registerUser(data: User): AxiosPromise<Response<User>> {
    return this.axios.post<Response<User>>(URI_REGISTER_USER, data);
  }

  public activeUser(token: string): AxiosPromise<Response<any>> {
    return this.axios.get<Response<any>>(URI_ACTIVE_USER + '/' + token);
  }

  public getUserProfile(): AxiosPromise<Response<User>> {
    return this.axios.post<Response<User>>(URI_GET_USER_PROFILE);
  }

  public updateUserProfile(data: User): AxiosPromise<Response<User>> {
    return this.axios.post<Response<User>>(URI_UPDATE_USER_PROFILE, data);
  }

  public forgetPassword(email: string) {
    return this.axios.get(URI_FORGET_PASSWORD + '/' + email);
  }

  public resetPassword(newPassword: string, token: string) {
    return this.axios.post(URI_RESET_PASSWORD, {newPassword, token});
  }

  public checkUserIsAuth() {
    return this.axios.post(URI_CHECK_USER_LOGIN);
  }

  public register(data: User): AxiosPromise<Response<User>> {
    return this.axios.post<Response<User>>(URI_REGISTER, data);
  }

  public verify(params: any): AxiosPromise<Response<User>> {
    return this.axios.post<Response<User>>(URI_VERIFY, null, { params });
  }

  public getProfile(): AxiosPromise<Response<User>> {
    return this.axios.get<Response<User>>(URI_GET_PROFILE);
  }

  public updateProfile(data: User, field: string): AxiosPromise<Response<User>> {
    return this.axios.post<Response<User>>(URI_UPDATE_PROFILE + field, data);
  }

  public changeLanguage(languageCode: number) {
    return this.axios.post(URI_CHANGE_LANGUAGE + languageCode);
  }
}

export const userService = new UserService();

