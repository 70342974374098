import Vue from 'vue';

const URI_LOGIN = process.env.VUE_APP_API_URL + '/webapi/epo-wallet/login';
const URI_GET_ACCOUNT = process.env.VUE_APP_API_URL + '/webapi/epo-wallet/get-account';
const URI_DEPOSIT = process.env.VUE_APP_API_URL + '/webapi/epo-wallet/deposit';

class EPOWalletService extends Vue {

  public login(email: string, password: string) {
    return this.axios.post(URI_LOGIN, { email, password });
  }

  public getAccount() {
    return this.axios.get(URI_GET_ACCOUNT);
  }

  public deposit(data: any) {
    return this.axios.post(URI_DEPOSIT, data);
  }
}

export const epoWalletService = new EPOWalletService();
