import { DataTableRequest } from '@/models';
import Vue from 'vue';

const URI_GET_ALL = process.env.VUE_APP_API_URL + '/webapi/transaction-history';

class TransactionHistoryService extends Vue {

  public getAll(params: DataTableRequest) {
    return this.axios.get(URI_GET_ALL, { params });
  }
}

export const transactionHistoryService = new TransactionHistoryService();
