export class Tournament {
  public id?: number = undefined;
  public fromDate?: Date = undefined;
  public toDate?: Date = undefined;
  public totalQuestions?: number = undefined;
  public questionRound?: number = undefined;
  public roundFee?: number = undefined;
  public prizeRank1: number = 0;
  public prizeRank2: number = 0;
  public prizeRank3: number = 0;
  public prizeRank4: number = 0;
  public prizeRank5: number = 0;
  public prizeRank6: number = 0;
  public prizeRank7: number = 0;
  public prizeRank8: number = 0;
  public prizeRank9: number = 0;
  public prizeRank10: number = 0;
  public name?: string = '';

  constructor(init?: Partial<Tournament>) {
    Object.assign(this, init);
  }
}
